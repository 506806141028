import { TBaseComponent } from "../base"
import {html} from 'lit'

export class TForm extends TBaseComponent 
{
    static properties = {
        method: {type: String},
        action: {type: String}
    }

    constructor()
    {
        super()
        this.method = this.method || "GET"
    }

    createRenderRoot() {
        return this;
    }

    render() 
    {
        console.log('rendering form..')
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <form action="${this.action}" method="${this.method}" @submit=${this.handleSubmit}>
                <div>
                    <slot/>
                </div>
                <div class="col-12 text-right">
                    <button class="btn btn-primary" type="submit">Enregistrer</button>
               </div>
            </form>       
        `;
    }

    handleSubmit(event) 
    {
        event.preventDefault()

        const form = this.querySelector('form')
        if(this.validate(form))
        {
            const inputs = [...this.querySelectorAll('input')]
            inputs.forEach( input => {
                let htmlInput = document.createElement('input')
                htmlInput.type = 'hidden'
                htmlInput.name = input.name
                htmlInput.value = input.value
                form.appendChild(htmlInput)
                console.log(htmlInput.type, htmlInput.name, htmlInput.value)
            })
            const select = [...this.querySelectorAll('select')]
            select.forEach( select => {
                console.log(select.name, select.selectedIndex, select.options[2].value)
                let htmlInput = document.createElement('input')
                htmlInput.name = select.name
                htmlInput.type = 'hidden'
                htmlInput.value = select.options[select.selectedIndex].value;
                form.appendChild(htmlInput)
                console.log(htmlInput.type, htmlInput.name, htmlInput.value)
            })
            // form.submit()
        }
    }

    validate(form) 
    {
        const inputs = [...this.querySelectorAll('input'), ...this.querySelectorAll('select')]
        for(let input of inputs)    console.log(input, input.checkValidity())
        return inputs.reduce((ok, input) => ok = ok && input.checkValidity(), true)
    }
}

if (!customElements.get('vx-form')) 
    customElements.define('vx-form', TForm)

