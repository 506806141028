import * as dashboard from './dashboards'
import * as sbc from './sbc'
import * as dids from './dids'
import * as templates from './templates'
import * as campaigns from './campaigns'
import * as campaign from './campaign'
import * as users from './users'
import * as users from './user'


export default function load (view, ctx) 
{
    if(!document.getElementById('root'))
    {    
        let root = document.createElement('section')
        root.id  = 'root'
        document.body.appendChild(root) 
        document.root = root
    }

    console.log('loading view', view)
    if(view === 'dashboard')    return dashboard.load(ctx)
    if(view === 'sbc')          return sbc.load(ctx)
    if(view === 'dids')         return dids.load(ctx)
    if(view === 'templates')    return templates.load(ctx)
    if(view === 'campaigns')    return campaigns.load(ctx)
    if(view === 'campaign')     return campaign.load(ctx)
    if(view === 'users')        return users.load(ctx)
    console.error(view, 'not found !!')
}

