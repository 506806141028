import {html, css, LitElement, unsafeCSS} from 'lit'

export class TSBCView extends LitElement 
{
    static styles = 
        css`
        `;

    static properties = {
        iconsLibrary: {attribute: false}
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <vx-layout>
                <vx-sidebar slot="sidebar">
                    <vx-sidebar-title slot="title" logo="https://www.voxymore.com/fr/img/logo.voxymore.png">CodzSword<br/><small>sub-title</small></vx-sidebar-title>
                    <vx-sidebar-group slot="menu" title="Telecom">
                        <vx-sidebar-item icon="list-check" href="/file">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="file-text" href="/file">SBC</vx-sidebar-item>
                        <vx-sidebar-item icon="file-text" href="/file">SVI</vx-sidebar-item>
                        <vx-sidebar-item icon="file-text" href="/file">BOT</vx-sidebar-item>
                        <vx-sidebar-item icon="file-text" href="/file">Enregistreur</vx-sidebar-item>
                    </vx-sidebar-group>
                </vx-sidebar>
                <div slot="content" class="container-fluid pe-5 pt-2">
                    <div class="row"><vx-card>Tableau de bord</vx-card></div>
                    <div class="row mt-3">
                        <div class="col-md-8">
                            <vx-card>card 1</vx-card>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6"><vx-card>card 2</vx-card></div>
                                <div class="col-md-6"><vx-card>card 2</vx-card></div>
                            </div>    
                            <div class="row pt-3">
                                <div class="col-md-6"><vx-card>card 2</vx-card></div>
                                <div class="col-md-6"><vx-card>card 2</vx-card></div>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-layout>
        `;
    }
}

if (!customElements.get('vx-sbc')) 
    customElements.define('vx-sbc', TSBCView);

export function load(ctx)
{
    console.log('loading dashboard', ctx)
    document.root.innerHTML = "<vx-dashboard></dashboard>"
}

