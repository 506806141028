import { TBaseComponent } from "../base"
import {html} from 'lit'

export class TInput extends TBaseComponent 
{
    static properties = {
        type: {type: String},
        name: {type: String},
        placeholder: {type: String},
        value: {type: String},
        label: {type: String},
        disabled: {type: Boolean},
        readonly: {type: Boolean},
        required: {type: Boolean},
        checked: {type: Boolean}
    }

    constructor()
    {
        super()
        this.type = this.type || 'text';
        this.disabled = this.disabled===undefined ? false : this.disabled 
        this.readonly = this.readonly===undefined ? false : this.readonly
    }


    createRenderRoot() 
    {
        return this;
    }

    render() 
    {
        console.log('initializing chart..')
        this.label = this.label || this.name
        this.placeholder = this.placeholder || this.label
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <div class="input-group mb-1">
                <div class="form-floating">
                    <input 
                        type="${this.type}" 
                        id="${this.name}" 
                        class="form-control" 
                        .name="${this.name}" 
                        .placeholder="${this.placeholder}" 
                        .value="${this.value}" 
                        ?disabled=${this.disabled}
                        ?readonly=${this.readonly}
                        ?checked=${this.checked}
                        ?required=${this.required}>
                    <label for="${this.name}">${this.label}</label>
                </div>
            </div>
        `;
    }
}

if (!customElements.get('vx-input')) 
    customElements.define('vx-input', TInput)

