import { TBaseComponent } from "../base"
import {html} from 'lit'

export class TTable extends TBaseComponent 
{
    static properties = {
        title: {type: String},
        type: {type: String},
        datasrc: {type: String},
        frequency: {type: Number},
        columns: {type: Object},
        cols: {type: Array},
        rows: {type: Array}
    }

    constructor()
    {
        super()
        console.log('columns', this.columns)
        this.type = this.type || 'stripped'
    }

    connectedCallback() {
        super.connectedCallback();
        this.fetchData();

    }

    fetchData() {
        var self = this
        if(this.datasrc)
            fetch(this.datasrc)
                .then(response => {
                    console.log('response', response)
                    if (!response.ok)   throw new Error('Network response was not ok')
                    return response.json()
                })
                .then(data => {
                    self.parseData(data)
                })
                .catch((error) => console.error('Error:', error) )
    }

    parseData(data) {
        let cols = new Set()
        let rows = []

        data.forEach( item => {
            Object.keys(item).forEach(cols.add, cols)
            rows.push(Object.values(item))
        } )
        this.cols = Array.from(cols)
        this.rows = rows
    }


    render() 
    {
        let cols = this.cols || []
        let rows = this.rows || []
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            table ...
            <table class="table table-stripped">
                <thead>
                    <tr>
                        ${cols.map( col => html`<th scope="col">${col}</th>` ) }    
                    </tr>
                </thead>
                <tbody>
                    ${rows.map( row => 
                        html`<tr>${row.map( cell => html`<td>${cell}` )}</tr>`
                    )}
                </tobyd>
            </table>
        `;
    }
}

if (!customElements.get('vx-table')) 
    customElements.define('vx-table', TTable)