import { TBaseComponent } from "../base"
import {html, css} from 'lit'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

export class TTabs extends TBaseComponent 
{
    static styles = 
    css`
        :host {
            font-family: var(--default-font-family);
        }

        button.nav-link {
            border-bottom: .5px solid grey;
        }
        button.active {
            font-weight: bold;
            border-bottom: 1px solid grey;
        }
    `;

    static properties = {
        tabs: {attribute: 'data-tabs', type: Array}
    }

    constructor()
    {
        super()
        this.type = this.type || 'text';
        this.disabled = this.disabled===undefined ? false : this.disabled 
        this.readonly = this.readonly===undefined ? false : this.readonly
    }

    firstUpdated()
    {
        const tabs = this.shadowRoot.querySelectorAll('button')
        tabs.forEach(el => {
            const tab = new bootstrap.Tab(el)
            console.log('tab', tab)
            el.addEventListener('click', event => {
                console.log('tab click !')
                event.preventDefault()
                tab.show()
            })
            el.addEventListener('shown.bs.tab', event => {
                console.log('show', event.target, event.relatedTarget)
                let prev = this.shadowRoot.querySelector(event.relatedTarget.getAttribute('data-bs-target'))
                let next = this.shadowRoot.querySelector(event.target.getAttribute('data-bs-target'))
                next.classList.add('show', 'active') // newly activated tab
                prev.classList.remove('show', 'active') // previous active tab
              })
        })
    }

    render() 
    {
        console.log('rendering tabs ', this.tabs)
        this.tabs = this.tabs || []
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <ul class="nav justify-content-end" role="tablist">
                ${this.tabs.map( (item, index) =>
                    html`
                        <li class="nav-item" role="presentation">
                            <button class="nav-link ${index === 0 ? 'active' : ''}" data-bs-toggle="tab" data-bs-target="#tab-panel-${index}" type="button" role="tab" aria-controls="tab-panel-${index}" aria-selected="true">${item}</button>
                        </li>
                    `
                )}
            </ul>
            <div class="tab-content mt-3" id="myTabContent">
                ${this.tabs.map( (item, index) =>
                    html`<div class="tab-pane fade ${index === 0 ? 'show active' : ''}" id="tab-panel-${index}" role="tabpanel" aria-labelledby="tab-panel-${index}" tabindex="0"><slot name="tab-${index+1}"/></div>`
                )}
            </div>
        `;
    }
}

if (!customElements.get('vx-tabs')) 
    customElements.define('vx-tabs', TTabs)

