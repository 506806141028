import {html, css} from 'lit'
import {TBaseComponent} from '../base'

class TSideBar extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --sidebar-width: 164px;
                --sidebar-background-color: #333;
                --sidebar-text-color: white;
                --link-text-color: white;
                --default-font-family: 'Poppins', sans-serif;
            }
        `]

    static properties = {
        items: {type: Array},
    };

    constructor() {
        super();
        this.items = [];
    }

    render() {
        return html`
            <header>
                <slot name="title"></slot>
            </header>
            <nav>
                <slot name="menu"></slot>
            </nav>
            `;
    }
}

if (!customElements.get('vx-sidebar')) 
    customElements.define('vx-sidebar', TSideBar);