import {html, css, LitElement, unsafeCSS} from 'lit'
//              @import url('/assets/icons/dist/assets/bootstrap-icons.css');
//              @import url('assets/icons/bootstrap-icons.css');
//              @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
//              @import url('assets/icons/test.css');

export class TBaseComponent extends LitElement 
{
    static styles = 
        css``;

    static properties = {
        iconsLibrary: {attribute: false}
    }

    constructor() 
    {
        super();
    }

    updated()
    {
        let root  = this.shadowRoot || this 
        let links = root.querySelectorAll('a')
        links.forEach(link => link.addEventListener('click', e => {
            if(window.router.navigate(link.href))   e.preventDefault()
        }))
    }

    render() {
        this.iconsLibrary = this.iconsLibrary || window.defaultIconsLibrary;
        console.log('iconsLibrary : ', this.iconsLibrary)
        // console.dir(this.constructor.styles, {depth: null})
        let HTML = html`
            ${this.iconsLibrary? html`<link rel="stylesheet" href="${this.iconsLibrary}">` : html``}
            <p class="icon-alarm">Hello, ${this.name} ! Greetings from VOXYMORE !</p><slot></slot>
            <button class="icon-alarm">hello</button>`;
        console.log('html', HTML)        
        return HTML
    }
}

if (!customElements.get('vx-base')) 
    customElements.define('vx-base', TBaseComponent);