import {html, css} from 'lit'
import {TCard} from './card'

class TValueCard extends TCard
{
    static styles = [
        TCard.styles,
        css`
        `]

    static properties = {
        title: {type: String},
        footer: {type: String},
        icon: {type: String},
        amount: {type: String},
        href: {type: String}
    };

    constructor() {
        super();
        this.imgpos = this.imgpos || 'top'
    }

    render() {
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <div class="card text-center pt-3">
                ${this.icon?  html`<h1><i class="icon-${this.icon}"></i></h1>` : html``}
                <div class="card-body">
                    ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                    <h1>${this.amount}</h1>
                    <div class="card-text"><slot/></div>
                </div>
                ${this.footer || this.href ? html`
                <div class="d-flex flex-row justify-content-between">
                    <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                    ${(this.href)? html`<span class="ps-2"><a href="${this.href}"><i class="icon-three-dots"></i></a></span>`: html``}
                </div>` : html``}
            </div>`
    }
}

if (!customElements.get('vx-value-card')) 
    customElements.define('vx-value-card', TValueCard);