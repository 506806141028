import page from 'page'
import {default as load} from '../views'
import {showAlertToast} from './../components/popups/toast'

const routes = [
    {uri: '/'                   , view: 'dashboard'},
    {uri: '/vsqv'                , view: 'vsqv'},
    {uri: '/vsqv/dids'           , view: 'dids'},
    {uri: '/vsqv/dids/:did'      , view: 'did'},
    {uri: '/vsqv/templates'      , view: 'templates'},
    {uri: '/vsqv/templates/:tid' , view: 'template'},
    {uri: '/vsqv/campaigns'      , view: 'campaigns'},
    {uri: '/vsqv/campaigns/:cid' , view: 'campaign'},
    {uri: '/users'               , view: 'users'},
    {uri: '/users/:uid'          , view: 'user'},
    {uri: '/sbc'                 , noSub: 'SBC'},
    {uri: '/svi'                 , noSub: 'SVI'},
    {uri: '/bot'                 , noSub: 'BOT'},
    {uri: '/rec'                 , noSub: 'enregistreur de communications'},
    {uri: '/rtc'                 , noSub: 'compte WebRTC'},
    {uri: '/trunk'               , noSub: 'trunk SIP'},
    {uri: '/queue'               , noSub: "centre d'appel"}
]

export class TRouter 
{
    constructor()
    {
        this.initialize()
    }

    initialize()
    {
        var self = this
//        page.base('/')
        routes.forEach( route => { if(route.view)  { console.log(route.uri, '-->', route.view); page(route.uri, (ctx) => self.load(route.view, ctx)) } })
        page('*', (ctx) => { console.log('page not found', ctx); page.redirect('/') });
        page()

        // document.body.addEventListener('click', e => 
        // {
        //     console.log('(1)', e.target.tagName)
        //     if(e.target.tagName === 'A') 
        //     {
        //         console.log('(2)')
        //         let hrefs = e.target.getAttribute('href')
        //         let route = routes.filter( route => route.uri.startsWith(hrefs)) 
        //         if(route)
        //         {
        //             console.log('(3)')
        //             e.preventDefault()
        //             if(route.noSub)     self.notSubscribed(route.noSub)
        //             else                page(hrefs)
        //         }     
        //     }
        // })
        console.log('router initialized')
    }

    load(view, ctx)
    {
        console.log('ROUTER', view, ctx.pathname, ctx)
        load(view, ctx)
    }

    notSubscribed(module)
    {
        console.log(module, 'not available')
        showAlertToast(`vous n'avez aucun ${module} dans votre infrastructure.`, 'danger')
        return true
    }

    navigate(url)
    {
        let urlData = new URL(url.toLowerCase())
        var handled = (urlData.hostname === window.location.hostname)
        if(handled)
        {
            let selRoute = null
            let uri = urlData.pathname || '/'
            for(let route of routes.filter( route => route.noSub ))
            {
                if(uri === route.uri)
                {
                    this.notSubscribed(route.noSub)
                    selRoute = route
                    break 
                }
            }
            console.log(`page(${uri})`)
            if(!selRoute)    page(uri)
            console.log('handled', uri)
            
        }
    console.log(url, "handled ?", handled, urlData.pathname)
        return handled
    }
}