import {html, css} from 'lit'
import {TBaseComponent} from '../base'

class TSideBarTitle extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --default-font-family: 'Poppins', sans-serif;
                --sidebar-title-font-size: 1.25rem;
                font-family: var(--default-font-family);
                font-size: var(--sidebar-title-font-size);
            }

            .flex-container {
                display: flex;
                flex-direction: row; 
                align-items: stretch; 
            }

            .left-part {
                flex: 2; 
                flex-basis: 40%;
                padding: 0 1rem 0 0;
                align-items: center; 
                vertical-align: middle !important;
            }

            .right-part {
                flex: 3;
                flex-basis: 60%;
                padding: 1rem 0 0 1rem;
                align-items: center;
                vertical-align: center;
                vertical-align: middle !important;
            }

            img {
                width: 100%;
            }

            @media (max-width: 768px) {
                .flex-container {
                    flex-direction: column; 
                }
            }
        `]

    static properties = {
        logo: {type: String}
    };

    constructor() {
        super();
        this.items = [];
    }

    render() {
        return html`            
        <div class="flex-container">
            ${ this.logo? html`<div class="left-part"><img src="${this.logo}"></div>` : html`` }
            <div class="right-part"><slot/></div>
        </div>
                `;
    }
}

if (!customElements.get('vx-sidebar-title')) 
    customElements.define('vx-sidebar-title', TSideBarTitle);