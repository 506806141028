import {html, css} from 'lit'
import {classMap} from 'lit/directives/class-map.js';
import {TBaseComponent} from '../base'

class TSideBarItem extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --sidebar-group-title-font-size: 1rem;
                --sidebar-group-padding: 0 1rem;
                font-family: var(--default-font-family)
            }

            a {
                cursor: pointer;
                text-decoration: none;
                display: block;
                vertical-align: -.125em;
                border-radius: 6px !important;
            }

            a {
                color: black;
                padding: .5rem;
                position: relative;
                display: block;
            }

            a:hover,
            a.active {
                color: white;
                background: black;
            }

            .title {
                font-family: var(---default-font-family);
                font-size: var(--sidebar-group-title-font-size);
                font-variant: small-caps;;
            }
            .items {
                padding: var(--sidebar-group-padding);
            }
        `]

    static properties = {
        href: {type: String},
        icon: {type: String},
        active: {type: Boolean},
        iconsLibrary: {attribute: false}
    };

    constructor() {
        super();
    }

    render() {
        console.log('icon :', this.icon)
        this.iconsLibrary = this.iconsLibrary || window.defaultIconsLibrary;
        const classes = {}
        classes[`icon-${this.icon}`] = this.icon && this.iconsLibrary
        classes['active'] = this.active

        return html`
            ${this.icon && this.iconsLibrary? html`<link rel="stylesheet" href="${this.iconsLibrary}">` : html``}
            <a class=${classMap(classes)} href="${this.href}"><slot/></a>
        `;
    }
}

if (!customElements.get('vx-sidebar-item')) 
    customElements.define('vx-sidebar-item', TSideBarItem);