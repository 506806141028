import { TBaseComponent } from "../base"
import {html} from 'lit'
import DataTable from 'datatables.net-bs5';

export class TDataTable extends TBaseComponent 
{
    static properties = {
        table       : {type: String},
        title       : {type: String},
        type        : {type: String},
        datasrc     : {type: String},
        frequency   : {type: Number},
        columns     : {type: Array},
        candelete   : {type: Boolean},
        canedit     : {type: Boolean},
        canview     : {type: Boolean},
        cancreate   : {type: Boolean},
        uuid        : {type: String, attribute: "data-uid"}
    }

    constructor()
    {
        super()
        this.table = this.table || 'table'
        this.type  = this.type || 'stripped'
    }

    firstUpdated()
    {
        console.log('first updated')
        this.fetchData()
    }

    fetchData() 
    {
        var self = this
        fetch(this.datasrc)
            .then(response => {
                console.log('response', response)
                if (!response.ok)   throw new Error('Network response was not ok')
                return response.json()
            })
            .then(data => {
                self.parseData(data)
            })
            .catch((error) => console.error('Error:', error) )
    }

    parseData(data) 
    {
        console.log('parseData', data)
        this.updateTable(data)
    }

    updateTable(tableData)
    {
        console.log('updateTable', this.type, tableData, this.columns)
        var self = this
        this.columns = this.columns || Object.keys(tableData[0]).map( item => { return {data: item, title: item} } )
        
        var btngroup = `<div class="btn-group" role="group">`
        if(this.canview)    btngroup += `<button class="btn btn-sm btn-outline-secondary icon-eye icon-no-padding"></button>`
        if(this.canedit)    btngroup += `<button class="btn btn-sm btn-outline-secondary icon-pencil icon-no-padding"></button>`
        if(this.candelete)  btngroup += '<button class="btn btn-sm btn-outline-secondary icon-x-lg icon-no-padding"></button>'
        btngroup += `</div>`

        const settings = {
            language: {
                décimal: ",",
                thousands: " ",
                info: 'page _PAGE_ de _PAGES_',
                infoEmpty: 'Aucune donnée à afficher',
                infoFiltered: '(sélection de _MAX_ lignes)',
                lengthMenu: ' _MENU_ lignes par page',
                zeroRecords: 'Pas de résultats..',
                loadingRecords: "Chargement...",
                search: "rechercher",
                paginate: {
                    "first":      "Premier",
                    "last":       "Dernier",
                    "next":       "Suivant",
                    "previous":   "Précédent"
                },
                aria: {
                    "sortAscending":  ": activer pour trier les données de la colonne du plus petit au plus grand",
                    "sortDescending": ": activer pour trier les données de la colonne du plus grand au plus petit"
                }
            },
            columns: [...this.columns,null],
            columnDefs: [{data: null, defaultContent: btngroup, targets: -1}],
            order: [[0, 'asc']],
            data: tableData
        } 
        const table  = this.shadowRoot.querySelector(this.table)
        const datatable = new DataTable(table, settings);
        datatable.on('click', 'tbody tr button', function (e) 
        { 
            console.dir(e); 
            console.log('clicked row', this.closest('tr')); 
            console.log('row', datatable.row(this.closest('tr')).data()); 
            self.onClickRow(datatable.row(this.closest('tr')).data()) 
        });
    }

    onClickRow(row)
    {
        console.log('onClickRow', row)
        let root = this.shadowRoot || this 
        let link = root.querySelector('a.hlnk')
        link.href = `${window.location.href}/${row.uid}`
        link.click()
        console.log('click link', link)
    }

    render() 
    {
        console.log('initializing chart..')
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <link rel="stylesheet" href="/assets/css/datatables.bs.css">
            ${this.cancreate? html`
            <div class="text-end pb-3"><button class="btn btn-secondary">NOUVEAU</button></div>`:html``}
            <table class="table table-striped table-hover table-sm" style="width:100%"></table>
            <a class="hlnk" href="#"></a>
        `;
    }
}

if (!customElements.get('vx-datatable')) 
    customElements.define('vx-datatable', TDataTable)

