import {html, css, LitElement, unsafeCSS} from 'lit'

export class TUsersView extends LitElement 
{
    static styles = 
        css`
        `;

    static properties = {
        iconsLibrary: {attribute: false}
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <vx-layout>
                <vx-sidebar slot="sidebar">
                    <vx-sidebar-title slot="title" xlogo="https://www.voxymore.com/fr/img/logo.voxymore.png">VOXYGEM<br/><small>telecom platform</small></vx-sidebar-title>
                    <vx-sidebar-group slot="menu" title="INFRASTRUCTURE">
                        <vx-sidebar-item icon="speedometer2" href="/">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-network" href="/sbc">SBC</vx-sidebar-item>
                        <vx-sidebar-item icon="diagram-3" href="/svi">SVI</vx-sidebar-item>
                        <vx-sidebar-item icon="chat-left-dots" href="/bot">BOT</vx-sidebar-item>
                        <vx-sidebar-item icon="telephone" href="/rtc">RTC</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-rack" href="/trunk">Interco</vx-sidebar-item>
                        <vx-sidebar-item icon="list-nested" href="/queue">Centre d'appels</vx-sidebar-item>
                        <vx-sidebar-item icon="rewind" href="/rec">Enregistreur</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="CONTRÔLE QUALITÉ">
                        <vx-sidebar-item icon="speedometer2" href="/vsqv">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="dice-6" href="/vsqv/dids">SDA</vx-sidebar-item>
                        <vx-sidebar-item icon="toggles" href="/vsqv/templates">TEMPLATES</vx-sidebar-item>
                        <vx-sidebar-item icon="send" href="/vsqv/campaigns">CAMPAGNES</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="ADMINISTRATION">
                        <vx-sidebar-item icon="people" href="/users" active>Utilisateurs</vx-sidebar-item>
                        <vx-sidebar-item icon="chevron-left" href="/logout">Quitter</vx-sidebar-item>
                    </vx-sidebar-group>
                </vx-sidebar>

                <div slot="content" class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>UTILISATEURS</vx-card></div>
                    <div class="row pt-3">
                        <vx-card header="Liste des Utilisateurs">
                            <vx-datatable 
                                datasrc="/assets/data/table.json" canedit canview candelete></vx-datatable>
                        </vx-card>
                    </div>
                </div>
            </vx-layout>
        `;
    }
}

if (!customElements.get('vx-users-view')) 
    customElements.define('vx-users-view', TUsersView);

export function load(ctx)
{
    console.log('loading dashboard', ctx)
    document.root.innerHTML = "<vx-users-view></vx-users-view>"
}

