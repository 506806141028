window.defaultIconsLibrary = '/assets/icons/icons.css'
import './components/base'
import './components/layout'
import './components/tabs/tab'
import './components/sidebar/sidebar'
import './components/sidebar/title'
import './components/sidebar/group'
import './components/sidebar/item'
import './components/cards/card'
import './components/cards/value_card'
import './components/charts/chart'
import './components/tables/table'
import './components/tables/datatable'
import './components/forms/form'
import './components/forms/input'
import './components/forms/select'
import './components/forms/submit'

import './app/voxyapp'




// import {showAlertToast, showConfirmToast} from './components/popups/toast'
// import {showModal, ShowPromptModal} from './components/popups/modal'

// const button = document.getElementById("liveToastBtn")
// button.addEventListener('click', () => showConfirmToast(`${Date.now()} - this is an alert !!!`, 'danger', 12000))
// button.addEventListener('click', () => showModal('body', 'header', 'footer', 'xl', true))
// button.addEventListener('click', () => ShowPromptModal('Do you accept ?').then((result) => console.log('modal result :', result)))