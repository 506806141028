import {Chart} from 'chart.js/auto'
import {css, html, LitElement} from 'lit-element'
const defaultConfig = {
    bar: {},
    pie: {}
}

export class TChart extends LitElement 
{
    static properties = {
        chart: {attribute: false},
        title: {type: String},
        type: {type: String},
        datasrc: {type: String},
        frequency: {type: Number},
        axis: {type: Object},
        options: {type: Object}
    }

    constructor()
    {
        super()
        this.type = this.type || 'bar'
    }

    firstUpdated()
    {
        console.log('firstUpdated')
        this.fetchData()
    }

    fetchData() {
        var self = this
        fetch(this.datasrc)
            .then(response => {
                console.log('response', response)
                if (!response.ok)   throw new Error('Network response was not ok')
                return response.json()
            })
            .then(data => {
                self.parseData(data)
            })
            .catch((error) => console.error('Error:', error) )
    }

    parseData(data) {
        console.log('parsing:', data)
        let labels = Object.keys(data)
        let values = Object.values(data)
        console.log('labels', labels)
        console.log('values', values)
        const chartData = {
            labels,
            datasets: [{
                label: this.title,
                data: values,
                borderWidth: 1,
                backgroundColor: ['green', 'red', 'orange', 'green', 'blue', 'yellow', 'magenta']
            }]
        }

        this.options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: this.title
              }
            }
          }

        this.updateChart(chartData)
    }

    updateChart(chartData)
    {
        console.log('updateChart', this.type, chartData, this.options)
        const type = this.type 
        const data = chartData        
        const options = this.options 
        if(!this.chart) 
        {
            const ctx = this.shadowRoot.querySelector('canvas').getContext('2d')
            console.log('ctx', ctx)
            this.chart = new Chart(ctx, {type, data, options})
        }
        else 
        {
            this.chart.type = this.type
            this.chart.data = data 
            this.chart.options = options 
            this.chart.update()
        }
    }

/*
    const type = 'bar'
    const data = {
      labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
      datasets: [{
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1
      }]
    }
    const options = {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }
*/

    render() 
    {
        console.log('initializing chart..')
        return html`
            <style>
                .chart-size{
                    position: relative;
                }
                canvas{
                    width:400px;
                    height:400px;
                }
            </style>
            <div class="chart-size">
                <canvas></canvas>
            </div>
        `;
    }
}

if (!customElements.get('vx-chart')) 
    customElements.define('vx-chart', TChart)