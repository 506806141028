import { TBaseComponent } from "../base"
import {html} from 'lit'

export class TSubmit extends TBaseComponent 
{
    static properties = {
    }

    constructor()
    {
        super()
        this.type = this.type || 'text';
        this.disabled = this.disabled===undefined ? false : this.disabled 
        this.readonly = this.readonly===undefined ? false : this.readonly
    }


    createRenderRoot() 
    {
        return this;
    }

    render() 
    {
        console.log('initializing chart..')
        this.label = this.label || this.name
        this.placeholder = this.placeholder || this.label
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <link rel="stylesheet" href="/assets/icons/icons.css">
            <div class="col-12 text-right">
                <button class="btn btn-primary" type="submit">Enregistrer</button>
            </div>
        `;
    }
}

if (!customElements.get('vx-submit')) 
    customElements.define('vx-submit', TSubmit)

