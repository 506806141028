import {html, css} from 'lit'
import {TBaseComponent} from './base'

class TLayout extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --content-padding: 2rem;
                --sidebar-width: 264px;
                --sidebar-padding: .5rem;
                --sidebar-transition: all 0.35s ease-in-out;
                --sidebar-background-color: white /*rgb(222, 226, 230)*/;
                --sidebar-shadow: 0 0 10px 0 #DBDBDB;
                --light-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                --dark-toggler-icon-bg : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            }

            main {
                align-items: stretch;
                display: flex;
                width: 100%;
            }

            aside {
                max-width: var(--sidebar-width);
                min-width: var(--sidebar-width);
                transition: var(--sidebar-transition);
                background-color: var(--sidebar-background-color);
                padding: var(--sidebar-padding);
                z-index: 1111;
                margin-left: calc((-1 * (var(--sidebar-width)) - (2 * (var(--sidebar-padding)))));
                -webkit-box-shadow: var(--sidebar-shadow);
                box-shadow: var(--sidebar-shadow);
                border-right: 1px solid #0000002d

            }

            .toggle {
                margin-left: 0;
            }
                    
            .page {
                display: flex;
                flex-direction: column;
                min-height: 100vh;
                width: 100%;
                overflow: hidden;
                transition: var(--sidebar-transition);
            }
                        
            .content {
                flex: 1;
                max-width: 100vw;
                width: 100vw;
                padding-left: var(--content-padding)
            }

            button {
                --bs-btn-padding-x: 0.75rem;
                --bs-btn-padding-y: 0.375rem;
                --bs-btn-font-family: ;
                --bs-btn-font-size: 1rem;
                --bs-btn-font-weight: 400;
                --bs-btn-line-height: 1.5;
                --bs-btn-color: white;
                --bs-btn-bg: transparent;
                --bs-btn-border-width: 0;
                --bs-btn-border-color: transparent;
                --bs-btn-border-radius: 6px;
                --bs-btn-hover-border-color: transparent;
                --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
                --bs-btn-disabled-opacity: 0.65;
                --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, .5);
                display: inline-block;
                padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
                font-family: var(--bs-btn-font-family);
                font-size: var(--bs-btn-font-size);
                font-weight: var(--bs-btn-font-weight);
                line-height: var(--bs-btn-line-height);
                color: var(--bs-btn-color);
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                user-select: none;
                border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
                border-radius: var(--bs-btn-border-radius);
                background-color: var(--bs-btn-bg);
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }    

            .toggler-icon {
                display: inline-block;
                width: 1.5em;
                height: 1.5em;
                vertical-align: middle;
                background-image: var(--light-toggler-icon-bg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
            }

            /* Responsive */

            @media (min-width:768px) {
                .content {
                    width: auto;
                }

                .navbar {
                    display: none !important;
                }

                .toggle {
                    margin-left: calc((-1 * (var(--sidebar-width)) - (2 * (var(--sidebar-padding)))));
                }

                aside {
                    margin-left: 0;
                }

            }
        `]

    static properties = {
    };

    constructor() {
        super();
    }

    toggleSidebar(e)
    {
        let sidebar = this.renderRoot.querySelector('aside')
        if(sidebar)     sidebar.classList.toggle("toggle")
    }

    render() {
        return html`
            <main>
                <!-- Sidebar -->
                <aside><slot name="sidebar"></slot></aside>
                <div class="page">
                    <nav class="navbar">
                        <button class="btn-toggle" type="button" @click="${this.toggleSidebar}">
                            <span class="toggler-icon"></span>
                        </button>
                    </nav>
                    <div class="content">
                        <div class="container-fluid">
                            <slot name="content"></slot>
                        </div>
                    </div>
                </div>
            </main>
        `;
    }
}

if (!customElements.get('vx-layout')) 
    customElements.define('vx-layout', TLayout);