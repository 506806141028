import {html, css, LitElement, unsafeCSS} from 'lit'

export class TDashboardView extends LitElement 
{
    static styles = 
        css`
        `;

    static properties = {
        iconsLibrary: {attribute: false}
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <vx-layout>
                <vx-sidebar slot="sidebar">
                    <vx-sidebar-title slot="title" xlogo="https://www.voxymore.com/fr/img/logo.voxymore.png">VOXYGEM<br/><small>telecom platform</small></vx-sidebar-title>
                    <vx-sidebar-group slot="menu" title="INFRASTRUCTURE">
                        <vx-sidebar-item icon="speedometer2" href="/" active="true">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-network" href="/sbc">SBC</vx-sidebar-item>
                        <vx-sidebar-item icon="diagram-3" href="/svi">SVI</vx-sidebar-item>
                        <vx-sidebar-item icon="chat-left-dots" href="/bot">BOT</vx-sidebar-item>
                        <vx-sidebar-item icon="telephone" href="/rtc">RTC</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-rack" href="/trunk">Interco</vx-sidebar-item>
                        <vx-sidebar-item icon="list-nested" href="/queue">Centre d'appels</vx-sidebar-item>
                        <vx-sidebar-item icon="rewind" href="/rec">Enregistreur</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="CONTRÔLE QUALITÉ">
                        <vx-sidebar-item icon="speedometer2" href="/vsqv">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="dice-6" href="/vsqv/dids">SDA</vx-sidebar-item>
                        <vx-sidebar-item icon="toggles" href="/vsqv/templates">TEMPLATES</vx-sidebar-item>
                        <vx-sidebar-item icon="send" href="/vsqv/campaigns">CAMPAGNES</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="ADMINISTRATION">
                        <vx-sidebar-item icon="people" href="/users">Utilisateurs</vx-sidebar-item>
                        <vx-sidebar-item icon="chevron-left" href="/logout">Quitter</vx-sidebar-item>
                    </vx-sidebar-group>
                </vx-sidebar>

                <div slot="content" class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>Tableau de bord</vx-card></div>
                    <div class="row">
                        <div class="col-md-8 mt-3">
                            <vx-card image="/assets/img/infrastructure.png" title="Infrastructure" imgpos="right">
                                Cette interface vous offre un outil pour piloter votre plateforme de téléphonie moderne intégrant une multitude de fonctionnalités avancées, notamment un Session Border Controller (SBC), des  serveurs vocaux interactifs (SVI), des Call Bots, des enregistreurs de communication, des centres d'appel avec des pôles de compétences et des files d'attentes, les interco opérateurs avec trunks SIP ainsi que les postes WebRTC.
                            </vx-card>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-12 col-xl-6 mt-3"><vx-value-card icon="hdd-network" title="SBC" amount="0" href="/sbc"></vx-value-card></div>
                                <div class="col-12 col-xl-6 mt-3"><vx-value-card icon="diagram-3" title="SVI" amount="0" href="/svi"></vx-value-card></div>
                            </div>    
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="chat-left-dots" title="BOT" amount="0" href="/sbc"></vx-value-card></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="diagram-3" title="SVI" amount="0" href="/svi"></vx-value-card></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="telephone" title="RTC" amount="0" href="/sbc"></vx-value-card></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="hdd-rack" title="TRUNK" amount="0" href="/svi"></vx-value-card></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="list-nested" title="QUEUE" amount="0" href="/sbc"></vx-value-card></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-value-card icon="rewind" title="ENREG" amount="0" href="/svi"></vx-value-card></div>
                        </div>
                    </div>
                </div>
            </vx-layout>
        `;
    }
}

if (!customElements.get('vx-dashboard')) 
    customElements.define('vx-dashboard', TDashboardView);

export function load(ctx)
{
    console.log('loading dashboard', ctx)
    document.root.innerHTML = "<vx-dashboard></vx-dashboard>"
}

