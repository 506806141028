import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';

export function showAlertToast(content, level='danger', duration=5000)
{
    const container = document.querySelector(".toast-container") || document.createElement('div')
    const toastelem = document.createElement('div')
    container.classList.add('toast-container', 'position-fixed', 'top-0', 'end-0', 'p-1')
    toastelem.classList.add('toast', `text-bg-${level}`)
    toastelem.setAttribute('role', 'alert')
    toastelem.setAttribute('aria-live', 'assertive')
    toastelem.setAttribute('aria-atomic', 'true')
    toastelem.setAttribute('data-bs-delay', duration)
    toastelem.innerHTML = `<div class="d-flex"><div class="toast-body">${content}</div><button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`
    toastelem.addEventListener('hidden.bs.toast', () => toastelem.remove())
    container.appendChild(toastelem)
    document.body.appendChild(container)
    const toast = new bootstrap.Toast(toastelem)
    toast.show()    
}

export function showConfirmToast(content)
{
    const container = document.getElementById("confirm-toast") || document.createElement('div')
    const toastelem = document.createElement('div')
    container.id = "confirm-toast"
    container.classList.add('toast-container', 'position-fixed', 'd-flex', 'justify-content-center', 'align-items-cente', 'w-100', 'top-50')
    toastelem.classList.add('toast')
    toastelem.setAttribute('role', 'alert')
    toastelem.setAttribute('aria-live', 'assertive')
    toastelem.setAttribute('aria-atomic', 'true')
    toastelem.innerHTML = `<div class="toast-body">
    ${content}
    <div class="mt-2 pt-2 border-top">
      <button type="button" class="btn btn-primary btn-sm">Take action</button>
      <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="toast">Close</button>
    </div>
  </div>`
    toastelem.addEventListener('hidden.bs.toast', () => toastelem.remove())
    container.appendChild(toastelem)
    document.body.appendChild(container)
    const toast = new bootstrap.Toast(toastelem)
    toast.show()    
}

