import {html, css} from 'lit'
import {TBaseComponent} from '../base'

class TSideBarGroup extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --sidebar-group-title-font-size: 1rem;
                --sidebar-group-title-padding: 1rem 0 0 0;
                --sidebar-group-items-padding: .5rem 0 0 1rem;
            }
            .title {
                font-family: var(---default-font-family);
                font-size: var(--sidebar-group-title-font-size);
                font-variant: small-caps;
                padding: var(--sidebar-group-title-padding);
            }
            .items {
                padding: var(--sidebar-group-items-padding);
            }
        `]

    static properties = {
        title: {type: String},
    };

    constructor() {
        super();
    }

    render() {
        return html`
            ${this.title? html`<div class="title">${this.title}</div>` : html``}
            <div class="items"><slot/></div>
            `;
    }
}

if (!customElements.get('vx-sidebar-group')) 
    customElements.define('vx-sidebar-group', TSideBarGroup);