import {TRouter} from './router'

export class TVoxyApp 
{
    constructor(name)
    {
        this.name = name
        this.initialize()
    }

    initialize()
    {
        this.router = new TRouter()
    }
}

window.voxyapp = new TVoxyApp()
window.router  = window.voxyapp.router