import {html, css} from 'lit'
import {TBaseComponent} from '../base'

export class TCard extends TBaseComponent
{
    static styles = [
        TBaseComponent.styles,
        css`
            :host {
                --card-box-shadow: 0 0 10px 0 #DBDBDB;
                --card-border-radius: 8px;
                --card-default-background: white;
                font-family: var(---default-font-family);
            }
            .card {
                -webkit-border-radius: var(--card-border-radius);
                border-radius: var(--card-border-radius);
                -webkit-box-shadow: var(--card-box-shadow);
                box-shadow: var(--card-box-shadow);
                background-color: var(--card-default-background);
            }

            .card-img-left {
                max-width: 100%;
                height: auto;
                border-bottom-left-radius: var(--card-border-radius) !important;
                border-top-left-radius: var(--card-border-radius) !important;
            }

            .card-img-right {
                max-width: 100%;
                height: auto;
                border-bottom-right-radius: var(--card-border-radius) !important;
                border-top-right-radius: var(--card-border-radius) !important;
            }

            .card-img-top,
            .card-img-bottom {
                width: 100%
            } 
        `]

    static properties = {
        title: {type: String},
        header: {type: String},
        footer: {type: String},
        image: {type: String},
        imgpos: {type: String},
        href: {type: String}
    };

    constructor() {
        super();
        this.imgpos = this.imgpos || 'top'
    }

    render() {
        let htmlContent = '';
        let htmlString  = '';
        if(!this.image)     this.imgpos = 'top'

        switch(this.imgpos)
        {
            case 'top':
                htmlContent = html`
                    <link rel="stylesheet" href="/assets/css/bootstrap.css">
                    <link rel="stylesheet" href="/assets/icons/icons.css">
                    <div class="card">
                        ${this.header ? html`
                        <div class="card-header">
                            ${this.header}
                        </div>` : html``}
                        ${this.image?  html`<img src="${this.image}" class="card-img-top">` : html``}
                        <div class="card-body">
                            ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                            <div class="card-text"><slot/></div>
                            ${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}
                        </div>
                        ${this.footer || this.href ? html`
                        <div class="card-footer d-flex flex-row justify-content-between">
                            <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                            ${(this.href)? html`<span class="ps-2"><a href="${this.href}"><i class="icon-three-dots"></i></a></span>`: html``}
                        </div>` : html``}
                    </div>`
                break;

            case 'bottom':
                htmlContent = html`
                    <link rel="stylesheet" href="/assets/css/bootstrap.css">
                    <link rel="stylesheet" href="/assets/icons/icons.css">
                    <div class="card">
                        ${this.header ? html`
                        <div class="card-header">
                            ${this.header}
                        </div>` : html``}
                        <div class="card-body">
                            ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                            <div class="card-text"><slot/></div>
                            ${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}
                        </div>
                        ${this.image?  html`<img src="${this.image}" class="card-img-bottom">` : html``}
                        ${this.footer || this.href ? html`
                        <div class="card-footer d-flex flex-row justify-content-between">
                            <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                            ${(this.href)? html`<span class="ps-2"><a href="${this.href}"><i class="icon-three-dots"></i></a></span>`: html``}
                        </div>` : html``}
                    </div>`
                break

            case 'left':
                htmlContent = html`
                    <link rel="stylesheet" href="/assets/css/bootstrap.css">
                    <link rel="stylesheet" href="/assets/icons/icons.css">
                    <div class="card">
                            ${this.header ? html`
                            <div class="card-header">
                                ${this.header}
                            </div>` : html``}
                            <div class="row g-0">
                            <div class="col-md-4"><img src="${this.image}" width="100%" class="card-img-left"></div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                                    <div class="card-text"><slot/></div>
                                </div>
                            </div>
                            ${this.footer || this.href ? html`
                            <div class="card-footer d-flex flex-row justify-content-between">
                                <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                                ${(this.href)? html`<span class="ps-2"><a href="${this.href}"><i class="icon-three-dots"></i></a></span>`: html``}
                            </div>` : html``}
                        </div>
                    </div>`  
                break

                case 'right':
                    htmlContent = html`
                        <link rel="stylesheet" href="/assets/css/bootstrap.css">
                        <link rel="stylesheet" href="/assets/icons/icons.css">
                        <div class="card">
                            ${this.header ? html`
                            <div class="card-header">
                                ${this.header}
                            </div>` : html``}
                            <div class="row g-0">
                                <div class="col-md-8">
                                    <div class="card-body">
                                        ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                                        <div class="card-text"><slot/></div>
                                    </div>
                                </div>
                                <div class="col-md-4"><img src="${this.image}" width="100%" class="card-img-right"></div>
                                ${this.footer || this.href ? html`
                                <div class="card-footer d-flex flex-row justify-content-between">
                                    <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                                    ${(this.href)? html`<span class="ps-2"><a href="${this.href}"><i class="icon-three-dots"></i></a></span>`: html``}
                                </div>` : html``}
                            </div>
                        </div>`  
                    break
    
        }
        console.log('htmlString : ', htmlString)
        return htmlContent
    }
}

if (!customElements.get('vx-card')) 
    customElements.define('vx-card', TCard);