import {html, css, LitElement, unsafeCSS} from 'lit'

export class TCampaignView extends LitElement 
{
    static styles = 
        css`
        `;

    static properties = {
        cid : {attribute: 'data-cid'},
        tabs: {attribute: false}
    }

    constructor() {
        super();
        this.tabs = ['paramètres', 'résultats']
    }

    render() {
        const columns = [
            {data: 'start'      , title: 'début'},
            {data: 'duration'   , title: 'durée'},
            {data: 'from'       , title: 'appelant'},
            {data: 'to'         , title: 'appelé'},
            {data: 'status'     , title: 'statut'}
        ]
        return html`
            <link rel="stylesheet" href="/assets/css/bootstrap.css">
            <vx-layout>
                <vx-sidebar slot="sidebar">
                    <vx-sidebar-title slot="title" xlogo="https://www.voxymore.com/fr/img/logo.voxymore.png">VOXYGEM<br/><small>telecom platform</small></vx-sidebar-title>
                    <vx-sidebar-group slot="menu" title="INFRASTRUCTURE">
                        <vx-sidebar-item icon="speedometer2" href="/">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-network" href="/sbc">SBC</vx-sidebar-item>
                        <vx-sidebar-item icon="diagram-3" href="/svi">SVI</vx-sidebar-item>
                        <vx-sidebar-item icon="chat-left-dots" href="/bot">BOT</vx-sidebar-item>
                        <vx-sidebar-item icon="telephone" href="/rtc">RTC</vx-sidebar-item>
                        <vx-sidebar-item icon="hdd-rack" href="/trunk">Interco</vx-sidebar-item>
                        <vx-sidebar-item icon="list-nested" href="/queue">Centre d'appels</vx-sidebar-item>
                        <vx-sidebar-item icon="rewind" href="/rec">Enregistreur</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="CONTRÔLE QUALITÉ">
                        <vx-sidebar-item icon="speedometer2" href="/vsqv">Tableau de bord</vx-sidebar-item>
                        <vx-sidebar-item icon="dice-6" href="/vsqv/dids">SDA</vx-sidebar-item>
                        <vx-sidebar-item icon="toggles" href="/vsqv/templates">TEMPLATES</vx-sidebar-item>
                        <vx-sidebar-item icon="send" href="/vsqv/campaigns" active>CAMPAGNES</vx-sidebar-item>
                    </vx-sidebar-group>
                    <vx-sidebar-group slot="menu" title="ADMINISTRATION">
                        <vx-sidebar-item icon="people" href="/users">Utilisateurs</vx-sidebar-item>
                        <vx-sidebar-item icon="chevron-left" href="/logout">Quitter</vx-sidebar-item>
                    </vx-sidebar-group>
                </vx-sidebar>

                <div slot="content" class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>VSQV / CAMPAGNES / ${this.cid}</vx-card></div>
                    <div class="row pt-3">
                        <vx-tabs data-tabs="${JSON.stringify(this.tabs)}">
                            <div slot="tab-1">
                                <vx-card header="Paramètres de la campagne">
                                    <div class="row pt-3">
                                        <div class="col-4"><b>Nom :</b>CAMPAGNE TEST ACCUEIL 3207</div>
                                        <div class="col-4"><b>SDA :</b>0972773207</div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-4"><b>Template :</b>STRESS TEST SANS SVI</div>
                                        <div class="col-4"><b>Appels simultanés :</b>400</div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-4"><b>Date de lancement :</b>30/08/2023 23:00:00</div>
                                        <div class="col-4"><b>Durée de la campagne :</b>00:00:06,395</div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-4"><b>Nombre d'appels Effectués :</b> 400</div>
                                        <div class="col-4"><b>Nombre d'appels réussits :</b> 392</div>
                                    </div>
                                </vx-card>
                            </div>
                            <div slot="tab-2">
                                <vx-card header="Résultats de la campagne">
                                    <div class="row">
                                        <div class="col-4">
                                            <vx-chart type="pie" datasrc="/assets/data/campaign.stats.json" title="résultats des appels"></vx-chart>
                                        </div>
                                        <div class="col-8">
                                            <vx-chart type="line" datasrc="/assets/data/campaign.delay.json" title="délais de réponse"></vx-chart>
                                        </div>
                                    </div>
                                </vx-card>
                                <vx-card header="Liste des appels">
                                    <div class="row">
                                        <div class="col">
                                            <vx-datatable 
                                                datasrc="/assets/data/campaign.calls.json" 
                                                columns=${JSON.stringify(columns)}>
                                            </vx-datatable>
                                        </div>
                                    </div>                        
                                </vx-card>
                            </div>
                        </vx-tabs>
                    </div>
                </div>
            </vx-layout>
        `;
    }
}

if (!customElements.get('vx-campaign-view')) 
    customElements.define('vx-campaign-view', TCampaignView);

export function load(ctx)
{
    console.log('loading dashboard', ctx)
    document.root.innerHTML = `<vx-campaign-view data-cid="${ctx.params.cid}"></vx-campaign-view>`
}

